import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    touches: 0,
    engagements: 0,
    vehicle: false,
    btnstop: false,
    vehicleid: '',
    btnstart: true,
    chat: 1,
    permission: [],
    modal: false,
    contacted: false,
    lostlead: '',
    alertFilter: 'today',
    from: '',
    to: '',
    taskFilter: 'month',
    task_from: '',
    task_to: '',
    appointFilter: 'month',
    appoint_from: '',
    appoint_to: '',
    leadFilter: '',
    lead_from: '',
    lead_to: '',
  },
  mutations: {
    updateTouchesCount(state, payload) {
      state.touches = payload
    },
    updateResponseCount(state, payload) {
      state.engagements = payload
    },
    updateVehicle(state, payload) {
      state.vehicle = payload
    },
    updatebtnstop(state, payload) {
      state.btnstop = payload
    },
    updatebtnstart(state, payload) {
      state.btnstart = payload
    },
    updatechat(state, payload) {
      state.chat = payload
    },
    updatepermission(state, payload) {
      state.permission = payload
    },
    updatevehicleid(state, payload) {
      state.vehicleid = payload
    },
    updateModal(state, payload) {
      state.modal = payload
    },
    updateContacted(state, payload) {
      state.contacted = payload
    },
    updateLostLead(state, payload) {
      state.lostlead = payload
    },
    updateAlertFilter(state, payload) {
      state.alertFilter = payload
    },
    updateTaskFilter(state, payload) {
      state.taskFilter = payload
    },
    updateAppointFilter(state, payload) {
      state.appointFilter = payload
    },
    updateLeadFilter(state, payload) {
      state.leadFilter = payload
    },
  },
  actions: {
  },
  modules: {
  }
})